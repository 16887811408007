import { render, staticRenderFns } from "./newusers.vue?vue&type=template&id=667567bc&scoped=true"
import script from "./newusers.vue?vue&type=script&lang=js"
export * from "./newusers.vue?vue&type=script&lang=js"
import style0 from "./newusers.vue?vue&type=style&index=0&id=667567bc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "667567bc",
  null
  
)

export default component.exports